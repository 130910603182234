import {AbstractRule} from "./rule.interface";
import TMOUR from "../../index";
import {CollisionRule} from "./collision-rule";
import {EVENTS_NAME} from "../../consts";

/**
 * It's not allowed to stop for 5seconds.
 */
export class TheFloorIsLavaRule extends CollisionRule implements AbstractRule {
  notificationHeader = 'Minesweeper';
  notificationBody = '';

  private countTriggered = 0;
  private lavaObjects: Phaser.Geom.Rectangle[] = [];
  private lavaCount = 20;
  private width = 32;
  private height = 32;

  constructor(game: TMOUR, amount: number, width: number, height: number) {
    super(game);
    this.reset(game);
    this.lavaCount = amount;
    this.width = width;
    this.height = height;
  }

  private initListeners(game: TMOUR): void {
    game.game.events.off(EVENTS_NAME.sceneStart,  this.reset.bind(this, game), this);
    game.game.events.on(EVENTS_NAME.sceneStart,  this.reset.bind(this, game), this);
  }

  addLava() {
    const position = this.getRandomPosition();
    const lava = new Phaser.Geom.Rectangle(position.x, position.y, 32, 32);
    this.lavaObjects.push(lava);
  }

  getRandomPosition() {
    const x = Math.floor(Math.random() * this.width) * 32;
    const y = Math.floor(Math.random() * this.height) * 32;
    return { x, y };
  }

  reset(game: TMOUR) {
    this.lavaObjects = [];
    this.countTriggered = 0;

    for (let i = 0; i < this.lavaCount; i++) {
      this.addLava();
    }

    this.initListeners(game);
  }

  check() {
    for (let i = 0; i < this.lavaObjects.length; i++) {
      const check = Phaser.Geom.Rectangle.Overlaps(this.game.player.getBounds(), this.lavaObjects[i]);

      if (check) {
        this.notify();

        this.game.cameras.main.flash(1000, 255, 0, 0, false);
        this.game.player.damage(10 * (this.countTriggered + 1));
        this.countTriggered++;

        this.lavaObjects.splice(i, 1);
        i--;
      }
    }
  }
}