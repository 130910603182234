import {Player} from "./player";
import TMOUR from "../index";

export class Ui {
  private healtElement: HTMLElement | null;

  constructor(
    private readonly game: TMOUR
  ) {
    this.healtElement = document.getElementById('current-health');
  }

  update() {
    if (this.healtElement) {
      this.healtElement.style.width = `${this.game.player.healthInPercent}%`;
    }
  }
}