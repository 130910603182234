import TMOUR from "../../index";
import Notification from "../../classes/notification";

export class TimeRule {
  notificationHeader: string = 'Default notification header';
  notificationBody: string = 'Default notification body';

  game: TMOUR;

  constructor(tmour: TMOUR) {
    this.game = tmour;
  }

  notify() {
    Notification.add(`<strong>${this.notificationHeader}</strong><br/>${this.notificationBody}`);
  }
}