import {Actor} from './actor';
import Notification from './notification';

export class Player extends Actor {
  private keyW: Phaser.Input.Keyboard.Key;
  private keyA: Phaser.Input.Keyboard.Key;
  private keyS: Phaser.Input.Keyboard.Key;
  private keyD: Phaser.Input.Keyboard.Key;

  private _inventory: string[] = [];

  private _health = {
    max: 100,
    current: 100,
  }

  private speed = 200;

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, 'player');
    // KEYS
    this.keyW = this.scene.input.keyboard!.addKey('W');
    this.keyA = this.scene.input.keyboard!.addKey('A');
    this.keyS = this.scene.input.keyboard!.addKey('S');
    this.keyD = this.scene.input.keyboard!.addKey('D');
    // PHYSICS
    this.getBody().setSize(24, 24);
    this.getBody().setOffset(4, 4);
  }

  update(): void {
    this.getBody().setVelocity(0);
    if (this.keyW?.isDown) {
      this.body!.velocity.y = this.speed * -1;
    }
    if (this.keyA?.isDown) {
      this.body!.velocity.x = this.speed * -1;
    }
    if (this.keyS?.isDown) {
      this.body!.velocity.y = this.speed;
    }
    if (this.keyD?.isDown) {
      this.body!.velocity.x = this.speed;
    }
  }

  damage(damage: number) {
    this._health.current -= damage;

    if (this._health.current <= 0) {
      Notification.add('You died');
      this.scene.scene.restart();
    }
  }

  addItem(item: string) {
    if (true === this.hasItem(item)) {
      return;
    }

    this._inventory.push(item);
    Notification.add(`<strong>You got ${item}<br /></strong> Use it wisely!`);
  }

  hasItem(item: string) {
    return this._inventory.includes(item);
  }

  get healthInPercent() {
    return this._health.current / this._health.max * 100;
  }
}
