class Notification {
  private static instance: Notification;

  static getInstance() {
    if (!Notification.instance) {
      Notification.instance=new Notification();
    }
    return Notification.instance;
  }

  add(text: string) {
    const baseElement = document.querySelector('#notification-container');
    const element = document.createElement('div');
    element.classList.add('notification');
    element.innerHTML = `<p>${text}</p>`;
    baseElement!.appendChild(element);

    setTimeout(() => {
      element.remove();
    }, 3000);
  }
}

export default Notification.getInstance();