export enum EVENTS_NAME {
    sceneStart = 'SCENE_START',
    playerCollisionWall = 'PLAYER_COLLISION_WALL',
    enterHotspot = 'PLAYER_ENTER_HOTSPOT',
    leaveHotspot = 'PLAYER_LEAVE_HOTSPOT',
    keyLoot = 'KEY_LOOT',
}

export enum GameStatus {
    WIN,
    LOSE,
}
