import {Player} from "../../classes/player";
import Notification from "../../classes/notification";
import {RulesManager} from "../../rules-manager";
import {Scene, Tilemaps} from "phaser";
import TMOUR from "../../index";
import {Ui} from "../../classes/ui";
import {EVENTS_NAME} from "../../consts";
import {Key} from "../../classes/key";

export class Level1 extends Scene implements TMOUR {
  // soundManager!: Phaser.Types.Sound.SoundConfig;
  map!: Tilemaps.Tilemap;
  tileset!: Tilemaps.Tileset | null;
  tileset_doors!: Tilemaps.Tileset | null;
  wallsLayer!: Tilemaps.TilemapLayer | null;
  fakeDoorsLayer!: Tilemaps.TilemapLayer | null;
  exitLayer!: Tilemaps.TilemapLayer | null;
  keyLayer!: Tilemaps.TilemapLayer | null;
  hotspotsLayer!: Tilemaps.TilemapLayer | null;
  _ui!: Ui;

  _player!: Player;
  rulesManager!: RulesManager;
  private _key!: Key;

  constructor() {
    super('level-1');
  }

  create(): void {
    this.sound.stopAll();
    this.initMap();
    this._player = new Player(this, 80, 300);
    this._ui = new Ui(this);
    this.physics.add.collider(this.player, this.wallsLayer!, () => {
      this.game.events.emit(EVENTS_NAME.playerCollisionWall);
    });

    this.physics.add.collider(this.player, this.fakeDoorsLayer!, () => {
      this.game.events.emit(EVENTS_NAME.playerCollisionWall);
    });

    this.physics.add.collider(this.player, this.exitLayer!, () => {
      if (this.player.hasItem('key')) {
        Notification.add('<strong>You are not dead!</strong>');
        this.scene.start('level-2');
      }
    });

    this.physics.add.overlap(this.player, this.keyLayer!, (player, key) => {
      if ((key instanceof Tilemaps.Tile) && key.properties?.isKey) {
        this.game.events.emit(EVENTS_NAME.keyLoot, {key});
      }
    });

    this.physics.add.overlap(this.player, this.hotspotsLayer!, (sprite, tile) => {
        if (!(tile instanceof Tilemaps.Tile) || tile.properties.collides) {
          this.game.events.emit(EVENTS_NAME.enterHotspot, sprite);
        }

        if (!(tile instanceof Tilemaps.Tile) || !tile.properties.collides) {
          this.game.events.emit(EVENTS_NAME.leaveHotspot, sprite);
        }
      },
    );

    if (!this.rulesManager) {
      this.rulesManager = new RulesManager(this, {width: 60, height: 60, TheFloorIsLava: {amount: 40}});
    }
    this._key = new Key(this);

    this.cameras.main.flash();
    this.game.events.emit(EVENTS_NAME.sceneStart);

    this.initCamera();

    setTimeout(() => {
      Notification.add('Run!');
    }, 1000);

    // Play music
    const basicLoop = this.sound.add('basic-loop');
    basicLoop.setLoop(true);
    basicLoop.play();
  }

  update(): void {
    this.rulesManager.update();
    this._player.update();
    this._ui.update();
  }

  protected initMap(): void {
    this.map = this.make.tilemap({key: 'level-1', tileWidth: 32, tileHeight: 32});
    this.tileset = this.map.addTilesetImage('tutorial', 'tiles');
    this.tileset_doors = this.map.addTilesetImage('door_spritesheet', 'doors');

    // Load walls and add collision
    this.wallsLayer = this.map.createLayer('Walls', this.tileset!, 0, 0);
    this.wallsLayer!.setCollisionByProperty({collides: true});

    // Load fake-doors and add collision
    this.fakeDoorsLayer = this.map.createLayer('FakeDoors', this.tileset_doors!, 0, 0);
    this.fakeDoorsLayer!.setCollisionByProperty({collides: true});

    // Load exit door
    this.exitLayer = this.map.createLayer('Exit', this.tileset_doors!, 0, 0);
    this.exitLayer!.setCollisionByProperty({collides: true});

    // Load key layer
    this.keyLayer = this.map.createLayer('Key', this.tileset!, 0, 0);

    // Set world borders
    this.physics.world.setBounds(0, 0, this.wallsLayer!.width, this.wallsLayer!.height);

    // Load hotspots
    this.hotspotsLayer = this.map.createLayer('Hotspots', this.tileset!, 0, 0);
    this.hotspotsLayer?.setAlpha(0);

    //this.showDebugWalls();
  }

  private initCamera(): void {
    this.cameras.main.setSize(this.game.scale.width, this.game.scale.height);
    this.cameras.main.startFollow(this.player, true, 0.09, 0.09);
    this.cameras.main.setZoom(3);
  }

  private showDebugWalls(): void {
    const debugGraphics = this.add.graphics().setAlpha(0.7);
    this.wallsLayer!.renderDebug(debugGraphics, {
      tileColor: null,
      collidingTileColor: new Phaser.Display.Color(243, 0, 0, 255),
    });
  }

  get player() {
    return this._player;
  }
}