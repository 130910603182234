import {AbstractRule} from "./rule.interface";
import TMOUR from "../../index";
import {CollisionRule} from "./collision-rule";
import {EVENTS_NAME} from "../../consts";

/**
 * It's not allowed to stop for 5seconds.
 */
export class JohnCarmackRule extends CollisionRule implements AbstractRule {
  notificationHeader: string = 'John Carmack';
  notificationBody: string = 'Are you misguided?';

  constructor(game: TMOUR) {
    super(game);

    this.reset(game);
    this.initListeners(game);
  }

  private initListeners(game: TMOUR): void {
    game.game.events.off(EVENTS_NAME.sceneStart,  this.reset.bind(this, game), this);
    game.game.events.on(EVENTS_NAME.sceneStart,  this.reset.bind(this, game), this);
    game.scene.scene.input.keyboard?.removeKey(Phaser.Input.Keyboard.KeyCodes.UP).off('down', this.pressed.bind(this));
    game.scene.scene.input.keyboard?.removeKey(Phaser.Input.Keyboard.KeyCodes.LEFT).off('down', this.pressed.bind(this));
    game.scene.scene.input.keyboard?.removeKey(Phaser.Input.Keyboard.KeyCodes.RIGHT).off('down', this.pressed.bind(this));
    game.scene.scene.input.keyboard?.removeKey(Phaser.Input.Keyboard.KeyCodes.DOWN).off('down', this.pressed.bind(this));

    game.scene.scene.input.keyboard?.addKey(Phaser.Input.Keyboard.KeyCodes.UP).on('down', this.pressed.bind(this));
    game.scene.scene.input.keyboard?.addKey(Phaser.Input.Keyboard.KeyCodes.LEFT).on('down', this.pressed.bind(this));
    game.scene.scene.input.keyboard?.addKey(Phaser.Input.Keyboard.KeyCodes.RIGHT).on('down', this.pressed.bind(this));
    game.scene.scene.input.keyboard?.addKey(Phaser.Input.Keyboard.KeyCodes.DOWN).on('down', this.pressed.bind(this));
  };

  pressed() {
    this.notify();

    this.game.player.damage(30);
  }

  reset(game: TMOUR) {

  }

  check() {

  }
}