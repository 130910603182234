import {AbstractRule} from "./rule.interface";
import TMOUR from "../../index";
import {EVENTS_NAME} from "../../consts";
import {CollisionRule} from "./collision-rule";

const initialDamageValue = 2;
const initialDamageSpeed = 200;

/**
 * It's not allowed to run near walls longer than 5 seconds
 */
export class ClaustrophobiaRule extends CollisionRule implements AbstractRule {
  notificationHeader = 'Claustrophobia';
  notificationBody = '';

  private countTriggered = 0;
  private startTime = new Date().getTime();
  private damageValue = initialDamageValue;
  // @ts-ignore
  private sound!;

  constructor(game: TMOUR) {
    super(game);
    this.initListeners(game);
    this.sound = game.sound.add('laugh-loop');
    this.sound.setLoop(false);
  }

  private initListeners(gameInstance: TMOUR): void {
    gameInstance.game.events.on(EVENTS_NAME.enterHotspot, this.onOverlap.bind(this, gameInstance), this);
    gameInstance.game.events.on(EVENTS_NAME.leaveHotspot, this.reset.bind(this, gameInstance), this);

    gameInstance.game.events.off(EVENTS_NAME.sceneStart, this.reset.bind(this, gameInstance), this);
    gameInstance.game.events.on(EVENTS_NAME.sceneStart, this.reset.bind(this, gameInstance), this);
  }

  reset(gameInstance: TMOUR) {
    this.countTriggered = 0;
    this.damageValue = initialDamageValue;
  }

  onOverlap(gameInstance: TMOUR) {
    const now = new Date().getTime();

    if (now - this.startTime > initialDamageSpeed) {
      if (this.countTriggered > 2) {
        this.notify();
        if (!this.sound.isPlaying) {
          this.sound.play();
        }
        this.game.player.damage(this.damageValue);
        this.damageValue = this.damageValue * 2;
      }

      this.countTriggered++;
      this.startTime = new Date().getTime();
    }
  }

  check() {

  }
}