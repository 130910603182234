import {Game, GameObjects, Scene, Tilemaps, Types} from 'phaser';
import {LoadingScene, Tutorial, Level1} from './scenes';

import './assets/css/style.css';
import {RulesManager} from "./rules-manager";
import {Player} from "./classes/player";
import {Ui} from "./classes/ui";

const gameConfig: Types.Core.GameConfig = {
  title: 'Phaser game tutorial',
  type: Phaser.WEBGL,
  parent: 'game',
  backgroundColor: '#351f1b',
  scale: {
    mode: Phaser.Scale.ScaleModes.NONE,
    width: window.innerWidth,
    height: window.innerHeight,
  },
  physics: {
    default: 'arcade',
    arcade: {
      debug: false,
    },
  },
  render: {
    antialiasGL: false,
    pixelArt: true,
  },
  callbacks: {
    postBoot: () => {
    },
  },
  canvasStyle: `display: block; width: 100%; height: 100%;`,
  autoFocus: true,
  audio: {
    disableWebAudio: false,
  },
  scene: [LoadingScene, Tutorial, Level1],
};

export default interface TMOUR extends Scene {
  map: Tilemaps.Tilemap;
  tileset: Tilemaps.Tileset | null;
  wallsLayer: Tilemaps.TilemapLayer | null;
  hotspotsLayer: Tilemaps.TilemapLayer | null;
  _player: GameObjects.Sprite;
  rulesManager: RulesManager;
  _ui: Ui;

  get player(): Player;
}

window.game = new Game(gameConfig);

