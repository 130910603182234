import TMOUR from "../index";
import {EVENTS_NAME} from "../consts";
import {Tilemaps} from "phaser";

export class Key {
  constructor(game: TMOUR) {
    this.bindListener(game);
  }

  private bindListener(game: TMOUR) {
    game.game.events.on(EVENTS_NAME.keyLoot, (props: {key: Phaser.Tilemaps.Tile}) => {
      props.key.alpha = 0;
      props.key.destroy();
      game.player.addItem('key');

    });
  }
}