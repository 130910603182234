import {AbstractRule} from "./rule.interface";
import TMOUR from "../../index";
import {EVENTS_NAME} from "../../consts";
import {CollisionRule} from "./collision-rule";

/**
 * It's not allowed to stop for 5seconds.
 */
export class EmbarrassingPenisFirst extends CollisionRule implements AbstractRule {
  notificationHeader = 'Embarrassing';
  notificationBody = '';

  private countTriggered = 0;
  private damageTimeout!: NodeJS.Timeout;

  constructor(game: TMOUR) {
    super(game);
    this.initListeners(game);
  }

  private initListeners(game: TMOUR): void {
    game.game.events.off(EVENTS_NAME.sceneStart, this.reset.bind(this), this);
    game.game.events.off(EVENTS_NAME.playerCollisionWall, this.onCollision.bind(this), this);

    game.game.events.on(EVENTS_NAME.sceneStart, this.reset.bind(this), this);
    game.game.events.on(EVENTS_NAME.playerCollisionWall, this.onCollision.bind(this), this);
  }

  reset() {
    this.countTriggered = 0;
  }

  onCollision() {
    clearTimeout(this.damageTimeout);

    this.damageTimeout = setTimeout(() => {
      this.notify();

      this.game.player.damage(5 + this.countTriggered);
      this.countTriggered++;
    }, 100);
  }

  check() {

  }
}