import {AbstractRule} from "./rules/rule.interface";
import {InflameRule} from "./rules/inflame";
import TMOUR from "../index";
import {ClaustrophobiaRule} from "./rules/claustrophobia";
import {EmbarrassingPenisFirst} from "./rules/Embarrassing-penis-first";
import {EVENTS_NAME} from "../consts";
import {TheFloorIsLavaRule} from "./rules/the-floor-is-lava";
import {JohnCarmackRule} from "./rules/john-carmack";

type ActiveRules = {
  name: string;
  rule: string;
  instance: AbstractRule;
}[]

export class RulesManager {
  private activeRules: ActiveRules = []

  constructor(phase: TMOUR, config: any) {
    this.activeRules.push({
      name: 'InflameRule',
      rule: 'InflameRule',
      instance: new InflameRule(phase)
    });

    this.activeRules.push({
      name: 'EmbarrassingPenisFirst',
      rule: 'EmbarrassingPenisFirst',
      instance: new EmbarrassingPenisFirst(phase)
    });

    this.activeRules.push({
      name: 'TheFloorIsLavaRule',
      rule: 'TheFloorIsLavaRule',
      instance: new TheFloorIsLavaRule(phase, config.TheFloorIsLava.amount, config.width, config.height)
    });

    this.activeRules.push({
      name: 'ClaustrophobiaRule',
      rule: 'ClaustrophobiaRule',
      instance: new ClaustrophobiaRule(phase)
    });

    this.activeRules.push({
      name: 'JohnCarmackRule',
      rule: 'JohnCarmackRule',
      instance: new JohnCarmackRule(phase)
    });
  }

  update() {
    this.activeRules.forEach((rule) => {
      rule.instance.check();
    })
  }
}