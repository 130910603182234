import TMOUR from "../../index";
import {AbstractRule} from "./rule.interface";
import Notification from "../../classes/notification";

export class CollisionRule implements AbstractRule {
    notificationHeader: string = 'Default notification header';
    notificationBody: string = 'Default notification body';

    protected game: TMOUR;

    constructor(tmour: TMOUR) {
        this.game = tmour;
    }

    check() {}

    notify() {
        Notification.add(`<strong>${this.notificationHeader}</strong><br/>${this.notificationBody}`);
    }
}

