import {AbstractRule} from "./rule.interface";
import {TimeRule} from "./time-rule";
import TMOUR from "../../index";
import {EVENTS_NAME} from "../../consts";

/**
 * It's not allowed to stop for 5seconds.
 */
export class InflameRule extends TimeRule implements AbstractRule {
  notificationHeader = 'Inflame';
  notificationBody = '';

  private startTime = new Date().getTime();
  private lastPosition!: Phaser.Types.Math.Vector2Like;
  private countTriggered = 0;

  constructor(game: TMOUR) {
    super(game);

    this.initListeners(game);
  }

  private initListeners(game: TMOUR): void {
    game.game.events.off(EVENTS_NAME.sceneStart,  this.reset.bind(this), this);

    game.game.events.on(EVENTS_NAME.sceneStart,  this.reset.bind(this), this);
  }

  reset() {
    this.countTriggered = 0;
  }

  check() {
    const now = new Date().getTime();
    this.game.player.getCenter()

    if (this.lastPosition && (this.lastPosition.x !== this.game.player.getCenter().x || this.lastPosition.y !== this.game.player.getCenter().y)) {
      this.startTime = new Date().getTime();
    }

    this.lastPosition = this.game.player.getCenter();

    if (now - this.startTime > 5000) {
      this.notify();

      this.game.player.damage(10 * (this.countTriggered + 1));
      this.startTime = now;
      this.countTriggered++;
    }
  }
}