import {GameObjects, Scene } from 'phaser';
import Notification from '../../classes/notification';

export class LoadingScene extends Scene {
    constructor() {
        super('loading-scene');
    }

    create(): void {
        const startButton = document.getElementById('start-game');
        const startOverlay = document.getElementById('start-screen-overlay');

        if (!startButton || !startOverlay) {
            this.scene.start('tutorial-scene');
        } else {
            startOverlay.classList.remove('hidden');
            startButton.addEventListener('click', () => {
                this.scene.start('tutorial-scene');
                // this.scene.start('level-1');
                startOverlay.classList.add('hidden');
            });
        }
    }

    preload(): void {
        this.load.baseURL = 'assets/';
        this.load.image('player', 'sprites/player.png');

        this.load.image({
            key: 'tiles',
            url: 'tilemaps/tiles/tutorial.png',
        });
        this.load.image({
            key: 'doors',
            url: 'tilemaps/tiles/door_spritesheet.png',
        });

        this.load.tilemapTiledJSON('tutorial', 'tilemaps/json/tutorial.json');
        this.load.tilemapTiledJSON('level-1', 'tilemaps/json/level-1.json');

        // Load audio files
        this.load.audio('basic-loop', [ 'audio/basic-loop.ogg' ]);
        this.load.audio('laugh-loop', [ 'audio/laugh-loop.ogg' ]);
    }
}